<template>
  <div class="contaienr-header grayBG">
    <div class="content flex sb_center">
      <div>
        <h2 class="size26 bold">{{ house.title || house.name }}</h2>
        <span class="size12 gray">{{ house.address }}</span>
      </div>
      <div
        class="row column c_center cursor"
        v-if="!isHide&&!isSourceERP"
      >
        <div
          @click="changeCollection()"
          class="follow-box whiteBG size16 col4d boxShadow mb5 cupo"
        >
          {{ isEstateAttention ? "取消关注" : "关注房源" }}
        </div>
        <span class="size12 gray">{{ house.saleAttention }}人已关注</span>
      </div>
       <div v-if="isSourceERP" class="kanfangShare" @click="showKanfangShare=true">
            分享房源
        </div>
    </div>
     <kf-qr-code-dialog
      :show-code="showKanfangShare"
      :page="tradeType"
      :houseNo="house.houseNo"
      @update-show-code="showKanfangShare = $event"
    />
  </div>
</template>
<script>
import { mapState } from "vuex";
import KfQrCodeDialog from "./KanfangShareCode.vue";
export default {
  name: "house",
  props: {
    tradeType: {
      type: Number,
    },
    house: {
      type: Object,
      required: true,
    },
    isHide: {
      default: false,
    },

    isEstateAttention: {
      default: false,
    },
    isDisabled: {
      type: Boolean,
      default: false,
    },
  },
  data(){
    return{
        showKanfangShare:false
    }
  },
   components: {
    KfQrCodeDialog: KfQrCodeDialog,
  },
  computed: {
    ...mapState(["user","isSourceERP"]),
  },
  methods: {
    changeCollection() {
      if (!this.isDisabled) {
        this.$emit("change-collection");
      }
    },
  },
  mounted() {},
};
</script>
<style scoped>
.contaienr-header {
  padding: 10px 0 17px;
}
.contaienr-header h2 {
  margin-bottom: 7px;
}

.follow-box {
  padding: 0 15px;
  height: 50px;
  line-height: 50px;
}
.kanfangShare{
    width: 100px;
    height: 40px;
    background: #ff9000;
    color: #fff;
    text-align: center;
    line-height: 40px;
    font-weight: 700;
    font-size: 16px;
    border-radius: 4px;
    cursor:pointer;

}
.kanfangShare:hover{
    background: #ff9f00;
}
</style>