<template>
  <div class="carousel">
    <img
      @click="showBigImg"
      class="carousel-img"
      :src="
        present_img.serverPath
          ? present_img.serverPath
          : imgs.length > 0 && imgs[0].serverPath
          ? imgs[0].serverPath
          : carousel_img
      "
      alt=""
    />
    <span class="img-span">{{present_img.description}}</span>
    <span
      class="img-span"
      v-if="present_img.isVideo"
    >视频</span>
    <span
      class="img-span"
      v-if="present_img.isVr"
    >VR</span>
    <!-- <img class="carousel-img" :src="carousel_img" alt="" /> -->
    <img
      v-if="present_img.isVr"
      src="@/assets/imgs/icon_vr_gold.gif"
      class="carousel-cupo"
      alt=""
      @click="goVr()"
    />
    <img
      v-if="present_img.isVideo"
      src="../assets/icon/video.png"
      class="carousel-cupo"
      alt=""
      @click="goVideo()"
    />
    <div
      class="viewBox-noScroll"
      v-if="imageList.length > 0"
    >
      <div
        ref="viewBox"
        class="viewBox"
      >
        <div
          ref="imgList"
          style="left: 0"
          class="imgList"
        >
          <div
            :ref="selectNum == index ? 'selectItem' : ''"
            v-for="(i, index) in imageList"
            @click="selectImage(index)"
            :key="index"
            class="img-item"
            :class="selectNum == index ? 'select' : ''"
          >
            <img
              style="width: 100%; height: 100%"
              :src="i.serverPath ? i.serverPath : carousel_img"
              alt=""
            />
            <div class="msak"></div>
          </div>
        </div>
        <span
          @click="leftClick()"
          class="viewBox-button column c_center viewBox-left"
        >
          <i
            style="color: #fff"
            class="el-icon-arrow-left"
          ></i>
        </span>
        <span
          @click="rightClick()"
          class="viewBox-button column c_center viewBox-right"
        >
          <i
            style="color: #fff"
            class="el-icon-arrow-right"
          ></i>
        </span>
        <!-- <div class="">
          <span v-for="i,index in carousel_num" :key="index"></span>
        </div> -->
      </div>
    </div>
    <BigImgCarousel
      v-if="imgs"
      :isShowBigImg="isShowBigImg"
      :houseImage="imgs"
      :newImage="present_img"
      @close="isShowBigImg=false"
    />
  </div>
</template>

<script>
import Constant from "@/common/Constant.js";
import BigImgCarousel from "./BigImgCarousel.vue";
export default {
  components: {
    BigImgCarousel,
  },

  props: {
    imgs: {
      type: Array,
    },
    coverImage: {
      type: String,
    },
    vrUrl: {
      type: String,
    },
    videoImageUrl: {
      type: String,
    },
    videoUrl: {
      type: String,
    },
    size: {
      type: String, //min  , max
    },
  },
  data() {
    return {
      selectNum: 0,
      //   imgs: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12],
      imageList: [],
      present_img: {},
      carousel_img: Constant.DEFAULT_HOUSE, // require("../assets/imgs/detail_icon/default-house.png"),
      num: 1,
      carousel_num: [],
      selectPageNum: 1,
      loading: false,

      isShowBigImg: false,
    };
  },
  mounted() {},
  created() {},

  methods: {
    showBigImg() {
      this.isShowBigImg = true;
    },
    leftClick() {
      if (this.loading) {
        return;
      }
      if (this.selectNum > 0) {
        this.selectNum += -1;
        this.present_img = this.imageList[this.selectNum];
      } else {
        this.selectNum = this.imageList.length - 1;
        this.present_img = this.imageList[this.selectNum];
      }
      let carousel_left = this.$refs.imgList.offsetLeft;
      let min_carousel_left = Math.abs(carousel_left);
      let selectItem_left = this.$refs.selectItem[0].offsetLeft;
      if (min_carousel_left >= selectItem_left) {
        this.carouselToLeft();
      }
    },
    rightClick() {
      if (this.loading) {
        return;
      }
      if (this.selectNum < this.imageList.length - 1) {
        this.selectNum += 1;
        this.present_img = this.imageList[this.selectNum];
      } else {
        this.selectNum = 0;
        this.present_img = this.imageList[0];
      }
      let carousel_left = this.$refs.imgList.offsetLeft;
      let max_carousel_left = Math.abs(carousel_left) + 555;
      let selectItem_left = this.$refs.selectItem[0].offsetLeft;
      // console.log("max_carousel_left>>", max_carousel_left);
      // console.log("selectItem_left>>", selectItem_left);
      if (max_carousel_left <= selectItem_left) {
        this.carouselToRight();
      }
    },
    carouselToLeft() {
      let carousel_left = this.$refs.imgList.offsetLeft;
      let carousel_width = this.$refs.imgList.clientWidth;
      if (carousel_left + 695 >= 0 && carousel_left != 0) {
        this.animation(carousel_left, 0);
        this.selectPageNum += -1;
      } else if (carousel_left + 695 < 0) {
        this.animation(carousel_left, carousel_left + 695);
        this.selectPageNum += -1;
      } else {
        this.animation(carousel_left, -carousel_width + 680);
        this.selectPageNum = this.num;
      }
    },
    carouselToRight() {
      let carousel_width = this.$refs.imgList.clientWidth;
      let carousel_left = this.$refs.imgList.offsetLeft;

      if (
        carousel_width + carousel_left - 695 <= 680 &&
        carousel_width + carousel_left - 695 > 0
      ) {
        this.animation(carousel_left, -carousel_width + 680);
        this.selectPageNum += 1;
      } else if (carousel_width + carousel_left - 695 > 680) {
        this.animation(carousel_left, carousel_left - 695);
        this.selectPageNum += 1;
      } else {
        this.animation(carousel_left, 0);
        this.selectPageNum = 0;
        // this.animation(carousel_left, 0);
      }
    },
    selectImage(index) {
      this.selectNum = index;
      this.present_img = this.imageList[index];
    },
    animation(start, end) {
      this.loading = true;
      //time 单位ms
      let distance = Math.abs(end - start);
      let toDistance = 0;
      let time = setInterval(() => {
        toDistance = toDistance + 5;
        if (end - start > 0) {
          this.$refs.imgList.style.left = start + toDistance + "px";
        } else {
          this.$refs.imgList.style.left = start - toDistance + "px";
        }
        if (toDistance >= distance) {
          clearInterval(time);
          this.loading = false;
        }
      }, 1);
    },
    goVr() {
      window.open(this.vrUrl);
    },
    goVideo() {
      window.open(this.videoUrl);
    },
  },
  watch: {
    imgs(newList) {
      newList.forEach((item) => {
        this.imageList.push(item);
      });

      if (this.imageList.length) {
        let num = parseInt(this.imageList.length / 5);
        if (this.imageList.length % 5 > 0) {
          num = num + 1;
        }
        this.num = num;
      }
    },
    videoUrl(newUrl) {
      if (newUrl) {
        let video = {
          serverPath: this.videoImageUrl ? this.videoImageUrl : this.coverImage,
          isVideo: true,
        };
        this.imageList.splice(0, 0, video);
        if (this.imageList.length) {
          let num = parseInt(this.imageList.length / 5);
          if (this.imageList.length % 5 > 0) {
            num = num + 1;
          }
          this.num = num;
        }
      }
    },

    vrUrl(newUrl) {

      if (newUrl) {
        let vr = {
          serverPath: this.videoImageUrl
            ?this.videoImageUrl: this.coverImage?this.coverImage
            : this.imgs[0]&&this.imgs[0].serverPath?this.imgs[0].serverPath:'',
          isVr: true,
        };
        this.imageList.splice(0, 0, vr);
        if (this.imageList.length) {
          let num = parseInt(this.imageList.length / 5);
          if (this.imageList.length % 5 > 0) {
            num = num + 1;
          }
          this.num = num;
        }
      }
    },

    imageList(newList) {
      this.present_img = newList[0];
    },
  },
};
</script>

<style lang="less" scoped>
.carousel {
  width: 680px;
  position: relative;
  left: 0;
  top: 0;
  .carousel-img {
    width: 680px;
    height: 444px;
    margin-bottom: 20px;
  }
  .carousel-cupo {
    width: 64px;
    height: 64px;
    position: absolute;
    left: 306px;
    top: 190px;
    cursor: pointer;
  }
  .viewBox-noScroll {
    width: 680px;
    height: 80px;
    overflow-y: hidden;
    .viewBox {
      width: 680px;
      overflow-x: hidden;
      overflow-y: hidden;
      height: 80px;
      padding-bottom: 20px;
      position: relative;
      left: 0;
      top: 0;
      .imgList {
        position: absolute;
        left: 0;
        top: 0;
        display: flex;
        flex-wrap: nowrap;
        height: 80px;
        .img-item {
          width: 124px;
          height: 80px;
          z-index: 50;
          cursor: pointer;
          position: relative;
        }
        .img-item:not(:last-child) {
          margin-right: 15px;
        }
      }
      .viewBox-button {
        width: 24px;
        height: 44px;
        z-index: 60;
        text-align: center;
        background-color: #171717;
        opacity: 0.5;
        cursor: pointer;
      }
      .viewBox-left {
        position: absolute;
        left: 0;
        top: 18px;
        border-radius: 0 4px 4px 0;
      }
      .viewBox-right {
        position: absolute;
        right: 0;
        top: 18px;
        border-radius: 4px 0 0 4px;
      }
    }
  }
}

.msak {
  width: 124px;
  position: absolute;
  top: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 60;
  background: rgba(255, 255, 255, 0.3);
}
.msak:hover {
  background: rgba(255, 255, 255, 0);
}
.select {
  .msak {
    background: none !important;
  }
}
.img-span {
  display: inline-block;
  position: absolute;
  top: 400px;
  left: 10px;
  line-height: 32px;
  text-align: center;
  padding: 0 11px;
  border-radius: 2px;
  background-color: rgba(0, 0, 0, 0.4);
  color: #fff;
  font-size: 12px;
}
</style>