<template>
  <div class="container">
    <house-header v-if="!isSourceERP"
      :tradeType="tradeType"
      :estateName="estateName"
      @update-estate="estateName = $event"
    />
    <HouseDetailHeader
      :house="houseInfo"
      :tradeType="tradeType"
      :isDisabled="isDisabled"
      :isEstateAttention="isEstateAttention"
      @change-collection="changeCollection"
    />
    <!-- <div class=" house-header row s_center">
            <div class="content row s_center content ">
                <div class="house-title flex1">
                    <h1 class="house-title-h">{{houseInfo.estateName}} {{houseInfo.room&&houseInfo.hall?houseInfo.room+'室'+houseInfo.hall+'厅':''}} {{houseInfo.orientation||''}}</h1>
                    <p class="house-title-p mt10 fontS14 colaa">{{houseInfo.sellPoint}}</p>
                </div>
                <div class="column c_center">
                    <el-button>关注</el-button>
                    <p class="mt10 fontS14 colaa">66人关注</p>
                </div>
            </div>
        </div> -->
    <div class="content">
      <div class="row house-imgs">
        <div class="pv10">
          <Carousel
            :imgs="imgs"
            :vrUrl="houseInfo.vrUrl"
            :videoUrl="houseInfo.videoUrl"
            :coverImage="houseInfo.coverImage"
            :videoImageUrl="houseInfo.videoImageUrl"
          />
          <div>
            <p
              class="row s_center pv20 fontS16 border-b "
              style="color: #394043"
            >
              <img
                class="mr30"
                style="width: 30px; height: 38px"
                src="../../assets/icon/house_icon_realHouse.png"
                alt=""
              />
              <span
                style=""
                class="flex1 row"
              >真实存在，真实在售，真实价格，真实图片，假一赔百元 <router-link
                  class="row e_center"
                  target="_blank"
                  :to="{
                      path: '/servicePromise',
                      query:{tradeType:2}
                    }"
                >
                  <img
                    class="ml10 cursor"
                    style="width:20px;height:20px;"
                    src="../../assets/icon/app_icon_warn.png"
                    alt=""
                  >
                </router-link></span>
              <span v-show="!isSourceERP"
                @click="isShowQRCode = true"
                class="green"
              >我要举报</span>
            </p>
            <p
              @click="goVr()"
              v-if="houseInfo.vrUrl"
              class="row s_center pv20 fontS16 border-b cursor"
              style="color: #394043"
            >
              <img
                class="mr30"
                style="width: 30px; height: 18px"
                src="../../assets/icon/house_icon_vr.png"
                alt=""
              />
              <span
                style=""
                class="flex1"
              >本房源已支持VR看房，在线看房，省时省力</span>
              <span class="green">立即看房</span>
            </p>
          </div>
        </div>
        <div class="house-imgs-right pv10 flex1 ml30">
          <div v-if="tradeType == 2">
            <div class="flex mb20 s_center">
              <span class="row s_center">
                <img
                  style="width: 18px; height: 14px"
                  src="../../assets/icon/web_icon_survey.png"
                  alt=""
                />
                <span class="size12 col4d ml5">实地核验</span>
              </span>
              <span v-show="!isSourceERP"
                @click="isShowQRCode = true"
                class="row s_center cupo"
                style="margin-left: 80px"
              >
                <img
                  style="width: 12px; height: 12px"
                  src="../../assets/icon/icon_fenxiang.png"
                  alt=""
                />
                <span class="size12 col4d ml5">分享此房源</span>
              </span>
            </div>
            <div class="pb20 flex border-b">
              <div
                class="red"
                style="margin-right: 32px"
              >
                <span style="font-size: 40px">{{
                  houseInfo.price || "--"
                  }}</span>
                <span class="size12">万</span>
              </div>
              <div class="flex column">
                <span class="size16 black">{{
                  (houseInfo.unitPrice || "--") + "元/平米"
                  }}</span>
                <a v-show="!isSourceERP"
                  @click="isShowQRCode = true"
                  href="javascript:;"
                  class="green size16"
                >首付及税费情况请咨询经纪人</a>
              </div>
            </div>
            <div class="pv20 border-b">
              <div class="flex">
                <div
                  v-if="houseInfo.room || houseInfo.hall"
                  class="flex1"
                >
                  <div class="bold size24">
                    <span v-if="houseInfo.room">{{
                      houseInfo.room + "室"
                      }}</span>
                    <span v-if="houseInfo.hall">{{
                      houseInfo.hall + "厅"
                      }}</span>
                  </div>
                  <p class="size12 black">
                    <span>{{ houseInfo.currentFloor + "/" || "" }}</span>
                    <span>{{
                      houseInfo.totalFloor
                      ? "共" + houseInfo.totalFloor + "层"
                      : "--"
                      }}</span>
                  </p>
                </div>
                <div
                  class="flex1"
                  v-if="houseInfo.orientation!='未选择'"
                >
                  <div class="bold size24">
                    <span>{{ houseInfo.orientation || "" }}</span>
                  </div>
                  <p class="size12 black flex">
                    <span v-if="houseInfo.houseStructure&&houseInfo.houseStructure!='未知'">{{
                      houseInfo.houseStructure
                      }}</span>
                    <span v-if="houseInfo.decorate&&houseInfo.decorate!='未知'">{{ "/"+ houseInfo.decorate }}</span>
                  </p>
                </div>
                <div class="flex1">
                  <div class="bold size24">
                    <span>{{
                      houseInfo.buildingArea
                      ? Math.floor(houseInfo.buildingArea)
                      : "--"
                      }}平米</span>
                  </div>
                  <p class="size12 black">
                    <span v-if="houseInfo.period">{{ houseInfo.period }} 建</span>
                    <!-- <span v-else>暂无数据</span> -->
                  </p>
                </div>
              </div>
              <div
                class="mt15 row s_center bikanhaof"
                v-show="mustSee"
              >
                <div class="bikanhaof-tag">必看好房</div>
                <div style="padding-left: 14px">诚信卖，省心买</div>
              </div>
            </div>

            <div class="mt20 size12 pb15 border-b">
              <div class="mb15">
                <span class="whiteGray">小区名称</span>
                <span class="ml20">{{ houseInfo.estateName }}</span>
                <a
                  v-show="estateInfo.lng"
                  @click="toMap"
                  class="ml20 green"
                >地图</a>
              </div>
              <div class="mb15 row">
                <span class="whiteGray">所在区域</span>
                <label class="ml20 row">
                  <span>{{ houseInfo.area2||'' }}</span>
                  <span>{{ houseInfo.area3||'' }}</span>
                </label>
              </div>
              <div class="mb15 row">
                <span class="whiteGray">看房时间</span>
                <label class="ml20 row">
                  <span>可看时间请咨询经纪人</span>
                </label>
              </div>
              <div class="mb15">
                <span class="whiteGray">风险提示</span>
                <a
                  @click="showRiskTip = true"
                  class="ml20 green"
                  href="javascript:;"
                >用户风险提示</a>
              </div>
              <div class="mb15 row">
                <span class="whiteGray">验真房源</span>
                <label class="ml20 row">
                  <span>{{ houseInfo.houseNo }}</span>
                </label>
              </div>
            </div>
          </div>
          <div class="mt20 pb10">
            <agent-data
              :agent-data="agentData"
              @show-code="toMimiWeChat"
            />
          </div>
        </div>
      </div>
      <div class="house-info">
        <ul
          ref="houseTabRef"
          class="row"
          style="height: 0px"
        ></ul>
        <div class="row">
          <div
            class="flex"
            style="padding-top: 28px"
          >
            <div style="width: 680px">
              <div id="house-info">
                <h1
                  style="padding: 20px 0 30px"
                  class="border-b size24 bold"
                >
                  房源基本信息
                </h1>

                <div
                  class="row size14 whiteGray border-b"
                  style="padding: 28px 0"
                >
                  <div style="width: 142px">
                    <span>基本属性</span>
                  </div>
                  <div class="flex1 row size14">
                    <div class="flex1">
                      <div class="mb15">
                        <span class="inline-b width90">房屋户型</span>
                        <label class="black">
                          <span v-if="houseInfo.room">{{
                            houseInfo.room + "室"
                            }}</span>
                          <span v-if="houseInfo.hall">{{
                            houseInfo.hall + "厅"
                            }}</span>
                        </label>
                      </div>
                      <div class="mb15">
                        <span class="inline-b width90">建筑面积</span>
                        <span class="black">{{
                          houseInfo.buildingArea?houseInfo.buildingArea + "㎡":''
                          }}</span>
                      </div>
                      <div class="mb15">
                        <span class="inline-b width90">建筑类型</span>
                        <span class="black"> 暂无数据</span>
                      </div>
                      <div class="mb15">
                        <span class="inline-b width90">房屋结构</span>
                        <span class="black">未知结构</span>
                      </div>
                      <div class="mb15">
                        <span class="inline-b width90">梯户比列</span>
                        <span class="black">暂无数据</span>
                      </div>
                      <div class="mb15">
                        <span class="inline-b width90">配备电梯</span>
                        <span class="black">{{houseInfo.elevatorType == 2 ? "有" : "无"}}</span>
                      </div>
                    </div>
                    <div class="flex1">
                      <div class="mb15">
                        <span class="inline-b width90">所在楼层</span>
                        <span class="black">{{
                          houseInfo.currentFloor?houseInfo.currentFloor +
                          `(共${houseInfo.totalFloor}层)`:''
                          }}</span>
                      </div>
                      <div class="mb15">
                        <span class="inline-b width90">套内面积</span>
                        <span class="black">暂无数据</span>
                      </div>
                      <div class="mb15">
                        <span class="inline-b width90">户型结构</span>
                        <span class="black">{{
                          houseInfo.houseStructure || "暂无数据"
                          }}</span>
                      </div>
                      <div class="mb15">
                        <span class="inline-b width90">房屋朝向</span>
                        <span class="black">{{
                          houseInfo.orientation || "暂无数据"
                          }}</span>
                      </div>
                      <div class="mb15">
                        <span class="inline-b width90">装修情况</span>
                        <span class="black">{{ houseInfo.decorate }}</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  class="row size14 whiteGray border-b"
                  style="padding: 28px 0"
                >
                  <div style="width: 142px">
                    <span>交易属性</span>
                  </div>
                  <div class="flex1 row">
                    <div class="flex1">
                      <div class="mb15">
                        <span class="inline-b width90">挂牌时间</span>
                        <label class="black">
                          <span>{{ houseInfo.onSaleTime | datetime }}</span>
                        </label>
                      </div>
                      <div class="mb15">
                        <span class="inline-b width90">上次交易</span>
                        <span class="black">暂无数据</span>
                      </div>
                      <div class="mb15">
                        <span class="inline-b width90">房屋年限</span>
                        <span class="black">暂无数据</span>
                      </div>
                      <div class="mb15">
                        <span class="inline-b width90">抵押信息</span>
                        <span class="black">无抵押</span>
                      </div>
                    </div>
                    <div class="flex1">
                      <div class="mb15">
                        <span class="inline-b width90">交易权属</span>
                        <span class="black">商品房</span>
                      </div>
                      <div class="mb15">
                        <span class="inline-b width90">房屋用途</span>
                        <span class="black">{{ houseInfo.propertyType }}</span>
                      </div>
                      <div class="mb15">
                        <span class="inline-b width90">产权所属</span>
                        <span class="black">共有</span>
                      </div>
                      <div class="mb15">
                        <span class="inline-b width90">房本备件</span>
                        <span class="black">详情请咨询经纪人</span>
                      </div>
                    </div>
                  </div>
                </div>
                <span class="size12 whiteGray">特别提示：本房源所示信息仅供参考，购房时请以该房屋档案登记信息、产权证信息以及所签订合同条款约定为准；本房源公示信息不做为合同条款，不具有合同约束力。</span>
              </div>
              <div
                id="house-feature"
                class="mt40"
              >
                <h1
                  style="padding: 20px 0 30px"
                  class="border-b size24 bold"
                >
                  本房源特色
                </h1>
                <div class="row feature size14 border-b">
                  <div style="width: 220px">
                    <span class="whiteGray">房源特色</span>
                  </div>
                  <ul class="flex">
                    <li
                      class="feature-tag size13 mr5"
                      v-for="item in houseInfo.tag"
                      @click="tagToList(item)"
                      :key="item"
                    >
                      {{ item }}
                    </li>
                  </ul>
                </div>
                <div class="row feature size14 border-b">
                  <div style="width: 220px">
                    <span class="whiteGray">核心卖点</span>
                  </div>
                  <span>此房户型方正，装修保养好，家具家电全新，采光充足。</span>
                </div>
                <div class="row feature size14 border-b">
                  <div style="width: 220px">
                    <span class="whiteGray">小区介绍</span>
                  </div>
                  <span class="flex1">{{ tabs[0].value || "暂无介绍" }}</span>
                </div>
                <div class="row feature size14 border-b">
                  <div style="width: 220px">
                    <span class="whiteGray">周边配套</span>
                  </div>
                  <span class="flex1">{{ tabs[1].value || "暂无介绍" }}</span>
                </div>
                <div class="row feature size14">
                  <div style="width: 220px">
                    <span class="whiteGray">交通出行</span>
                  </div>
                  <span class="flex1">{{ tabs[2].value || "暂无介绍" }} </span>
                </div>
                <span class="size12 whiteGray">注：1.房源介绍中的周边配套、在建设施、规划设施、地铁信息、绿化率、得房率、容积率等信息为通过物业介绍、
                  房产证、实勘、政府官网等渠道获取，因时间、政策会发生变化，与实际情况可能略有偏差，房源介绍仅供参考。
                  2.房源介绍中与距离相关的数据均来源于百度地图。3.土地使用起止年限详见业主土地证明材料或查询相关政府部门
                  的登记文件。</span>
              </div>
              <div
                id="house-takeLook"
                class="mt40"
              >
                <h1
                  style="padding: 20px 0 30px"
                  class="border-b size24 bold"
                >
                  经纪人带看反馈
                </h1>
                <ul
                  class="house-takeLook"
                  v-if="takeFeedback.length > 0"
                >
                  <li
                    class="row pv20 border-b"
                    v-for="(item, index) in takeFeedback"
                    :key="index"
                  >
                    <img
                      :src="item.agentVO.avatar || defaultAvatar"
                      style="width: 56px; height: 56px; border-radius: 28px"
                      alt=""
                    />
                    <div class="flex1 column ml30">
                      <div class="takeLook-item-title row s_center">
                        <span class="takeLook-item-agentName">{{
                          item.agentVO.trueName || "经纪人"
                          }}</span>
                        <span
                          class="brand_tag"
                          v-show="item.agentVO.departmentName"
                        >{{ item.agentVO.departmentName }}</span>
                        <div class="flex1 row e_center">
                          <!-- <span class="agent-phone">{{
                            item.agentVO.fixTel || ""
                            }}</span> -->
                          <el-button
                            class="chatButton" v-show="!isSourceERP"
                            @click="isShowQRCode = true"
                            style="
                              padding: 0px 12px;
                              height: 30px;
                              line-height: 30px;
                              text-align: center;
                            "
                          ><i class="el-icon-phone"></i>联系我</el-button>

                          <el-button v-show="!isSourceERP"
                            class="chatButton"
                            @click="isShowQRCode = true"
                            style="
                              padding: 0px 12px;
                              height: 30px;
                              line-height: 30px;
                              text-align: center;
                            "
                          ><i class="el-icon-chat-dot-round"></i>在线问</el-button>
                        </div>
                      </div>
                      <div class="takeLook-item-des">
                        {{ item.takeLookFeedbackDesc || "" }}
                      </div>
                      <!-- <div class="takeLook-item-record" v-show="item.followTime">{{moment(item.followTime).format("YYYY-MM-DD") }}带客户看过此房</div> -->
                      <div
                        class="takeLook-item-record"
                        v-show="item.followTime"
                      >
                        {{ item.followTime }}带客户看过此房
                      </div>
                    </div>
                  </li>
                </ul>
                <p
                  v-else
                  class="size18 whiteGray pv20 border-b center"
                >
                  暂无带看反馈
                </p>
              </div>
              <div
                id="house-image"
                class="mt40"
                v-show="imgs.length > 0"
              >
                <h1
                  style="padding: 20px 0 30px"
                  class="border-b size24 bold"
                >
                  此房源照片
                </h1>
                <div class="container">
                  <div class="list">
                    <div
                      class="list-item"
                      v-for="(image, index) in imgs"
                      :key="index"
                      :style="index % 2 == 1 ? 'margin-right:10px' : ''"
                      @click="showBigImgF(image)"
                    >
                      <img
                        :src="image.serverPath"
                        alt=""
                      />
                      <span>{{ image.description }}</span>
                    </div>
                  </div>
                </div>
              </div>
              <div id="house-estate">
                <div style="margin-top: 50px">
                  <div class="flex sb_center col4d mb20">
                    <h1 class="size24 bold">{{ estateInfo.name }}简介</h1>
                    <router-link
                      target="_blank"
                      class="size14 cursor gray"
                      :to="{
                        name: 'house-estate-detail',
                        query: { guid: estateInfo.guid },
                      }"
                    >查看小区详情</router-link>
                  </div>
                  <div class="flex">
                    <div class="flex1 size18">
                      <div
                        class="mb20"
                        v-show="estateInfo.averagePrice"
                      >
                        <span class="width130 inline-b whiteGray">小区均价</span>
                        <span class="red">{{
                          estateInfo.averagePrice!=0? Math.floor(estateInfo.averagePrice) + "元/㎡" : "未知"
                          }}</span>
                      </div>
                      <div class="mb20">
                        <span class="width130 inline-b whiteGray">建筑年代</span>
                        <span class="black">{{
                          estateInfo.completeYear
                          ? estateInfo.completeYear + "年建成"
                          : "--"
                          }}</span>
                      </div>
                      <div class="mb20">
                        <span class="width130 inline-b whiteGray">楼栋总数：</span>
                        <span class="black">{{
                          estateInfo.buildingNum
                          ? estateInfo.buildingNum + "栋"
                          : "--"
                          }}</span>
                      </div>
                      <div class="mb20">
                        <span class="width130 inline-b whiteGray">房屋总数：</span>
                        <span class="black">{{
                          estateInfo.roomNum ? estateInfo.roomNum + "户" : "--"
                          }}</span>
                      </div>
                    </div>
                    <div>
                      <img
                        v-if="estateInfo.coverImage"
                        @error="coverImgErr"
                        style="width: 200px; height: 150px"
                        :src="estateInfo.coverImage"
                        alt=""
                      />
                      <!-- <img
                        v-else
                        style="width: 200px; height: 150px"
                        :src="defaultCoverImg"
                        alt=""
                      /> -->
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        ref="mapHouseRef"
        id="house-near"
        class="mt40"
      >
        <map-peripheral
          v-if="estateInfo.lng && estateInfo.lat"
          :lng="estateInfo.lng"
          :lat="estateInfo.lat"
          :estateName="estateInfo.name"
        />
      </div>

      <div
        class="mt40"
        id="house-recommend"
      >
        <h1 class="size24 col4d mb20 bold">为您推荐优质房源</h1>
        <ul
          v-if="recommendHouse.length"
          class="near-house flex wrap"
        >
          <router-link
            class="near-house-item"
            target="_blank"
            v-for="item in recommendHouse"
            :key="item.guid"
            :to="{
              name: 'second-house-detail',
              query: {
                guid: item.guid,
                tradeType: tradeType,
              },
            }"
          >
            <div
              class="mb10"
              style="width: 100%; height: 220px"
            >
              <img
                v-if="item.coverImage"
                class="all"
                :src="item.coverImage"
                alt=""
              />
              <img
                v-else
                class="all"
                :src="defaultCoverImg"
                alt=""
              />

              <span
                class="tag-item size14"
                v-for="tag in item.tag"
                v-show="tag == '必看好房'"
                :class="{ activeTag: tag == '必看好房' }"
                :key="tag"
              >
                {{ tag }}
              </span>
            </div>
            <div v-if="tradeType == 1">
              <div class="flex sb_center size16 bold">
                <p
                  style="width: 160px"
                  class="size16 black ellipsis"
                >
                  {{ item.title }}
                </p>
                <span class="red">{{ item.rentPrice + "元/月" }}</span>
              </div>
              <p class="size14 whiteGray">
                <span v-if="item.region">{{ item.region + "/" }}</span>
                <span v-if="item.orientation">{{
                  item.orientation + "/"
                  }}</span>
                <span v-if="item.buildingArea">{{
                  item.buildingArea + "㎡"
                  }}</span>
              </p>
            </div>
            <div v-else>
              <p class="size16 black">{{ item.region }}</p>
              <p class="size16 black">{{ item.title }}</p>
              <div class="flex sb_center mt10">
                <div class="size14 whiteGray">
                  <span v-if="item.room">{{ item.room + "室" }}</span>
                  <span v-if="item.hall">{{ item.hall + "厅" }}</span>
                  <span
                    v-if="item.buildingArea"
                    class="ml10"
                  >{{
                    item.buildingArea + "平米"
                    }}</span>
                </div>
                <span class="size16 bold red">{{ item.price + "万" }}</span>
              </div>
            </div>
          </router-link>
        </ul>
        <p
          v-else
          class="size18 whiteGray pv20 border-b center"
        >附近暂无房源</p>

      </div>
      <div
        class="mt40"
        id="house-recommend"
      >
        <h1 class="size24 col4d mb20 bold">同价位二手房</h1>
        <ul
          v-if="samePriceHouse.length"
          class="near-house flex wrap"
        >
          <router-link
            class="near-house-item"
            target="_blank"
            v-for="item in samePriceHouse"
            :key="item.guid"
            :to="{
              name: 'second-house-detail',
              query: {
                guid: item.guid,
                tradeType: tradeType,
              },
            }"
          >
            <div
              class="mb10"
              style="width: 100%; height: 220px"
            >
              <img
                v-if="item.coverImage"
                class="all"
                :src="item.coverImage"
                alt=""
              />
              <img
                v-else
                class="all"
                :src="defaultCoverImg"
                alt=""
              />
              <span
                class="tag-item size14"
                v-for="tag in item.tag"
                v-show="tag == '必看好房'"
                :class="{ activeTag: tag == '必看好房' }"
                :key="tag"
              >
                {{ tag }}
              </span>
            </div>

            <div>
              <p class="size16 black">{{ item.region }}</p>
              <p class="size16 black">{{ item.title }}</p>
              <div class="flex sb_center mt10">
                <div class="size14 whiteGray">
                  <span v-if="item.room">{{ item.room + "室" }}</span>
                  <span v-if="item.hall">{{ item.hall + "厅" }}</span>
                  <span
                    v-if="item.buildingArea"
                    class="ml10"
                  >{{
                    item.buildingArea + "平米"
                    }}</span>
                </div>
                <span class="size16 bold red">{{ item.price + "万" }}</span>
              </div>
            </div>
          </router-link>
        </ul>
        <p
          v-else
          class="size18 whiteGray pv20 border-b center"
        >附近暂无房源</p>

      </div>
      <div class="mt30" v-show="!isSourceERP">
        <h1 class="size24 col4d mb20 bold">
          我有房子要{{ tradeType == 2 ? "卖" : "租" }}
        </h1>
        <div class="row">
          <div class="yezhu-item yezhu-item-gujia flex1 column c_center">
            <!-- <img src="../../assets/imgs/yezhu-gujia.png" alt="" class="yezhu-item-img"> -->
            <div class="yezhu-item-marsk"></div>
            <div class="yezhu-item-msg-tit">不知道如何定价</div>
            <div class="yezhu-item-msg-sub-tit">到成交频道查看成交明细</div>
            <router-link
              :to="{
                path: '/house/' + tradeType,
              }"
              target="_blank"
              style="color: #fff; z-index: 4"
            >查成交﹥</router-link>
          </div>
          <div class="yezhu-item yezhu-item-maifang flex1 column c_center">
            <!-- <img src="../../assets/imgs/yezhu-gujia.png" alt="" class="yezhu-item-img"> -->
            <div class="yezhu-item-marsk"></div>
            <div class="yezhu-item-msg-tit">把房源委托给{{companyConfig.firstName}}</div>
            <div class="yezhu-item-msg-sub-tit">
              专业经纪人·线上千万级浏览量
            </div>
            <router-link
              :to="{
                path: '/owner/' + tradeType,
              }"
              target="_blank"
              style="color: #fff; z-index: 4"
            >发布房源﹥</router-link>
          </div>
          <div class="yezhu-item yezhu-item-xiazai flex1 column c_center">
            <!-- <img src="../../assets/imgs/yezhu-gujia.png" alt="" class="yezhu-item-img"> -->
            <div class="yezhu-item-marsk"></div>
            <div class="yezhu-item-msg-tit">已有房源在{{companyConfig.firstName}}销售</div>
            <div class="yezhu-item-msg-sub-tit">
              去{{companyConfig.firstName + companyConfig.lastName}}APP业主中心管理委托
            </div>
            <div
              class=" hoverDiv"
              style=" z-index: 4"
            >
              <span
                class="hoverDiv-cu"
                style="color: #fff; z-index: 4"
              >去APP管理委托﹥</span>
              <span class="layer-qrcode">
                <span class="icon-qrcode">
                  <img
                    :src="companyConfig.downloadUrl"
                    alt=""
                  >
                </span>
                <span class="txt">去{{companyConfig.firstName + companyConfig.lastName}}APP提升卖房速度</span>
                <span class="sub-txt">APP·移动卖房新体验</span>
              </span>
            </div>

          </div>
        </div>
      </div>
    </div>
    <risk-tip
      :show-risk-tip="showRiskTip"
      @update-show-risk-tip="showRiskTip = $event"
    />
    <!--  -->
    <div
      v-if="needFxied"
      class="needFxied "
      style="background:#303035"
    >
      <ul
        class="row content relative"
        style="height: 50px"
      >
        <li
          style="line-height: 50px; text-align: center"
          class="size20 relative ph20 cursor"
          v-for="tab in houseTabs"
          :key="tab.key"
          v-show="tab.key!=5||houseInfo.lat"
        >
          <!-- :href="tab.id" -->
          <a
            class="needFxied-item"
            :style="tab.choose?'color:#fff':''"
            @click="onClickTabItem(tab)"
          >
            {{ tab.title }}
          </a>

        </li>
        <div
          v-if="needRightFxied"
          class="needRightFxied"
        >
          <recommend-agent
            :recommend-agent="otherAgents"
            title="联系经纪人"
            :isEstateAttention="isEstateAttention"
            :show-recommend="showRecommend"
            @change-collection="changeCollection"
            @show-code="toMimiWeChat"
          />
        </div>
      </ul>
    </div>
    <qr-code-dialog
      :show-code="isShowQRCode"
      :page="13"
      :houseGuid="guid"
      :tradeType="tradeType"
      @update-show-code="isShowQRCode = $event"
    />

    <BigImgCarousel
      :isShowBigImg="isShowBigImg"
      :houseImage="imgs"
      :newImage="clickImg"
      @close="close"
    />
  </div>
</template>

<script>
import HouseHeader from "../../components/house-header";
import Dialog from "../../components/Dialog";
import Constant from "../../common/Constant.js";
// import moment from 'moment'
import Carousel from "../../components/Carousel.vue";
import BigImgCarousel from "../../components/BigImgCarousel.vue";
import HouseDetailHeader from "../../components/HouseDetailHeader.vue";
import AgentData from "../../components/AgentData.vue";
import RiskTip from "../../components/RiskTip.vue";
import tool from "@/utils/tool.js";
// import HousePriceComputed from '../../components/HousePriceComputed'
import MapPeripheral from "../../components/MapPeripheral.vue";
import RecommendAgent from "../../components/RecommendAgent.vue";
import realHouseServer from "../../services/realHouseServer.js";
import userServer from "../../services/userServer.js";
import houseServer from "@/services/houseServer.js";
import { mapState } from "vuex";

export default {
  name: "houseDetail",
  components: {
    HouseHeader,
    Carousel,
    AgentData,
    RiskTip,
    // HousePriceComputed,
    MapPeripheral,
    HouseDetailHeader,
    RecommendAgent,
    QrCodeDialog: Dialog,
    BigImgCarousel,
  },
  props: {},
  data() {
    return {
      estateName: "",
      guid: "",
      type: 1,
      statisType: 3,
      tradeType: 2,
      houseInfo: {},
      imgs: [], //[1,2,3,4,5,6,7,8]
      clickImg: {},
      isShowBigImg: false,

      takeFeedback: [],
      agentData: {},

      showRiskTip: false,

      deleteTags: [],
      defaultCoverImg: Constant.DEFAULT_HOUSE,
      defaultAvatar: Constant.DEFAULT_AVATAR,
      iframeUrl: Constant.TAXATION_URL,
      dynamic: {},
      lng: 104.071,
      lat: 30.56,
      tabs: [
        {
          name: "小区介绍",
          value: "",
        },
        {
          name: "周边配套",
          value: "",
        },
        {
          name: "交通出行",
          value: "",
        },
      ],
      estateInfo: {
        lng: "",
        lat: "",
        name: "",
      },
      houseTabs: [],
      matching: [
        {
          title: "洗衣机",
          icon: require("../../assets/icon/web_icon_machine.png"),
          isHave: true,
        },
        {
          title: "空调",
          icon: require("../../assets/icon/web_icon_conditioner.png"),
          isHave: false,
        },
        {
          title: "衣柜",
          icon: require("../../assets/icon/web_icon_wardrobe.png"),
          isHave: true,
        },
        {
          title: "电视",
          icon: require("../../assets/icon/web_icon_tv.png"),
          isHave: true,
        },
        {
          title: "冰箱",
          icon: require("../../assets/icon/web_icon_refrigerator.png"),
          isHave: true,
        },
        {
          title: "热水器",
          icon: require("../../assets/icon/web_icon_heater.png"),
          isHave: true,
        },
        {
          title: "床",
          icon: require("../../assets/icon/web_icon_bed.png"),
          isHave: true,
        },
        {
          title: "暖气",
          icon: require("../../assets/icon/web_icon_heating.png"),
          isHave: true,
        },
        {
          title: "WiFi",
          icon: require("../../assets/icon/web_icon_wifi.png"),
          isHave: true,
        },
        {
          title: "天然气",
          icon: require("../../assets/icon/web_icon_natural.png"),
          isHave: true,
        },
      ],
      otherAgents: [],
      nearHouse: [],
      recommendHouse: [],
      samePriceHouse: [],
      dealHistories: [],
      searchParamsTake: {
        page: 1,
        itemsPerPage: 6,
        companyGuid: Constant.DEFULT_COMPANY_GUID,
      },
      searchParams: {
        page: 1,
        itemsPerPage: 6,
        type: 2,
      },
      takeRecords: [],
      needFxied: false,
      needRightFxied: false,

      isDisabled: false,
      isShowQRCode: false,
      isEstateAttention: false,
      showRecommend: true,
      mustSee:false
    };
  },
  computed: {
    ...mapState(["user", "companyConfig","isSourceERP"])
  },

  watch: {
    $route(newVal, val) {
      if (newVal.params.tradeType !== val.params.tradeType) {
        this.tradeType = +newVal.params.tradeType;
      }
    },
  },
  methods: {
    tagToList(item) {
      let tag = "";
      switch (item) {
        case "满五年":
          tag = "fiveYears";
          break;
        case "满两年":
          tag = "twoYears";
          break;
        case "急售房源":
          tag = "quickSale";
          break;
        case "VR房源":
          tag = "existVr";
          break;
        case "随时看房":
          tag = "isKey";

          break;
      }

      this.$router.push({
        path: "/house/2",
        query: { param: [{ key: "houseTags", value: tag }] },
      });
    },
    close(isShow) {
      // console.log("close>>", isShow);
      this.isShowBigImg = false;
    },
    showBigImgF(img) {
      this.clickImg = img;
      this.isShowBigImg = true;
    },
    goVr() {
      window.open(this.houseInfo.vrUrl);
    },
    getTradeType() {
      const {
        params: { tradeType },
      } = this.$route;
      this.tradeType = +tradeType;
      this.onLoad();
    },
    toMimiWeChat() {
      this.isShowQRCode = true;
    },
    onLoad() {
      const { guid, type, statisType } = this.$route.query;
      this.guid = guid;
      this.type = type;
      this.statisType = statisType;
      this.houseTabs = [
        {
          title: "房源基本信息",
          choose: true,
          key: 1,
          id: "#house-info",
        },
        {
          title: "本房源特色",
          choose: false,
          key: 2,
          id: "#house-feature",
        },
        // {
        //   title: "带看反馈",
        //   choose: false,
        //   key: 3,
        //   id: "#house-takeLook",
        // },
        {
          title: "房源照片",
          choose: false,
          key: 3,
          id: "#house-image",
        },
        {
          title: "小区简介",
          choose: false,
          key: 4,
          id: "#house-estate",
        },
        {
          title: "周边配套",
          choose: false,
          key: 5,
          id: "#house-near",
        },
        // {
        // 	title: '看房记录',
        // 	choose: false,
        // 	key: 5,
        // 	id: '#house-take-look'
        // },
        // {
        //   title: "历史成交",
        //   choose: false,
        //   key: 6,
        //   id: "#house-estate-history",
        // },
      ];

      this.getHouseInfo();
      this.loadAgentRecommend();
      this.loadHouseImags();
      this.getTakeFeedback();
      if (this.user) {
        this.loadHouseCollection(guid);
      }
    },
    toMap() {
      this.onClickTabItem({
        title: "周边配套",
        choose: false,
        key: 5,
        id: "#house-near",
      });
    },
    onClickTabItem(item) {
      if (item.choose) return;
      document.querySelector(item.id).scrollIntoView();
      // let houseTabs = this.deepCopy(this.houseTabs);
      this.houseTabs.forEach((ele) => {
        if (item.key === ele.key) {
          ele.choose = true;
        } else {
          ele.choose = false;
        }
      });
      // console.log("this.houseTabs >>>", this.houseTabs);
      // this.houseTabs = houseTabs;
    },
    deepCopy(obj) {
      return JSON.parse(JSON.stringify(obj));
    },
    onScroll() {
      let scrollTop = document.documentElement.scrollTop;
      let offsetTop = this.$refs.houseTabRef.offsetTop;
      let mapScrollTop = this.$refs.mapHouseRef.offsetTop;
      // console.log('offsetTop>>>>',offsetTop)
      // console.log('scrollTop>>>>',scrollTop)
      // console.log('mapScrollTop>>>>',mapScrollTop)
      if (scrollTop >= offsetTop) {
        this.needFxied = true;
        let houseTabs_ = this.deepCopy(this.houseTabs);
        houseTabs_.forEach((item, index) => {
          if (index < houseTabs_.length - 1) {
            if (
              scrollTop >= item.maxTop - 50 &&
              scrollTop < houseTabs_[index + 1].maxTop
            ) {
              item.choose = true;
            } else {
              item.choose = false;
            }
          } else {
            if (scrollTop > item.maxTop - 50) {
              item.choose = true;
            } else {
              item.choose = false;
            }
          }
        });
        this.houseTabs = houseTabs_;
        if (scrollTop < mapScrollTop - 500) {
          this.needRightFxied = true;
        } else {
          this.needRightFxied = false;
        }
      } else {
        this.needFxied = false;
        this.needRightFxied = false;
      }
    },

    //获取房源详情.
    async getHouseInfo() {
      const { data } = await realHouseServer.getRealHouseDetail(this.guid);
      // console.log(`getRealHouseDetail >`, JSON.stringify(data))
      const {
        vrUrl,
        videoUrl,
        houseNo,
        companyGuid,
        estateGuid,
        // propertyType,
        // agentGuid
      } = data;
      // let coverImage = data.coverImage || Constant.DEFAULT_HOUSE
      // this.title = propertyType
      if (!vrUrl) {
        // this.addTeleteTags('vr')
      } else {
        this.vrUrl = vrUrl;
        // this.houseImages.vr.push(coverImage)
      }
      if (!videoUrl) {
        // this.addTeleteTags('video')
      } else {
        this.videoUrl = videoUrl;
        // this.houseImages.video.push(coverImage)
      }
      this.houseInfo = data;
      this.searchParamsTake.houseNo = houseNo;
      this.searchParams.estateGuid = estateGuid;
      this.searchParams.companyGuid = companyGuid;

      this.setMustSee()
      this.loadEstate(estateGuid);
      this.loadHouseDynamic(houseNo, companyGuid);
      this.loadHistoryDeal();
      this.loadSecondHouse(estateGuid);
      this.getSameHouseByEstate(estateGuid, this.houseInfo.price);
    },
    //获取房源图片
    async loadHouseImags() {
      const { data } = await realHouseServer.getHouseresource(this.guid);
      this.imgs = data;
    },
    //获取带看反馈
    async getTakeFeedback() {
      const { data } = await realHouseServer.getTakeFeedback({
        houseGuid: this.guid,
        page: 1,
        itemPage: 3,
      });
      this.takeFeedback = data;
    },
    // 楼盘数据
    async loadEstate(estateGuid) {
      const { data } = await realHouseServer.getEstateById(estateGuid);
      const { entertainment, transportation, business, lat, lng } = data;
      // console.log(`getEstateById`, data)
      let tabs = JSON.parse(JSON.stringify(this.tabs));
      for (var i = 0; i < tabs.length; i++) {
        if (tabs[i].name == "小区介绍") {
          tabs[i].value = entertainment;
        }
        if (tabs[i].name == "交通出行") {
          tabs[i].value = transportation;
        }
        if (tabs[i].name == "周边配套") {
          tabs[i].value = business;
        }
      }
      this.tabs = tabs;
      this.estateInfo = data;
      this.lat = lat;
      this.lng = lng;
      if (this.tradeType == 1) {
        // this.loadNeighborhoodHouse(lng, lat)
      }
    },

    // 房源动态
    async loadHouseDynamic(houseNo, companyGuid) {
      let params = {
        page: 1,
        itemsPerPage: 1,
        houseNo,
        companyGuid,
      };
      const { data } = await realHouseServer.getRealHouseDynamicById(params);
      this.dynamic = data;
    },
    // 历史成交
    async loadHistoryDeal() {
      const { data } = await realHouseServer.loadHistoryDeal(this.searchParams);
      const { records, total } = data;
      this.dealHistories = records;
      this.totalPageHistory = total;
    },
    handlePrevChangeHistory(val) {
      this.changeHistoryDeal(val);
    },
    handleNextChangeHistory(val) {
      this.changeHistoryDeal(val);
    },
    handleCurrentChangeHistory(val) {
      this.changeHistoryDeal(val);
    },
    changeHistoryDeal(val) {
      this.searchParams.page = val;
      this.loadHistoryDeal();
    },
    // 周边推荐
    async loadSecondHouse(estateGuid) {
      let params = {
        page: 1,
        itemsPerPage: 8,
        tradeType: this.tradeType,
        estateGuid: estateGuid,
        houseGuid: this.guid,
      };
      const { data } = await realHouseServer.loadSecondHouse(params);
      // console.log(`loadSecondHouse >>`, data.records)
      this.recommendHouse = data.records;
    },
    //同价位房源
    async getSameHouseByEstate(estateGuid, price) {
      let params = {
        page: 1,
        itemsPerPage: 8,
        tradeType: 2,
        maxPrice: price * 10000 + 10000,
        minPrice: price * 10000 - 10000,
        estateGuid: estateGuid,
      };
      const { data } = await realHouseServer.getSameHouseByEstate(params);
      this.samePriceHouse = data.records;
    },
    // 推荐经纪人
    async loadAgentRecommend() {
      let params = {
        houseGuid: this.guid,
        limitCount: 10,
      };

      const { data } = await realHouseServer.getRealHouseRecommendById(params);
      if (data.length) {
        this.otherAgents = data;
        this.agentData = data[0];
      }
    },

    async changeCollection() {
      if (this.user) {
        if (this.isEstateAttention) {
          //取消关注
          this.isDisabled = true;
          const param = {
            houseGuid: this.houseInfo.guid,
            tradeType: 2,
          };
          const [err, { code }] = await tool.to(
            houseServer.cancelCollection(param)
          );
          this.isDisabled = false;

          if (err) {
            this.$message.error(err.message);
            this.isDisabled = false;
            return;
          }
          this.getHouseInfo();
          if (code === 0) {
            this.$message({
              type: "success",
              duration: 1500,
              message: "取消关注成功",
            });
            this.isEstateAttention = false;
          }
        } else {
          //关注
          this.isDisabled = true;
          const params = {
            houseGuid: this.houseInfo.guid,
            tradeType: 2,
          };
          const [err, { code }] = await tool.to(
            houseServer.addCollection(params)
          );
          this.isDisabled = false;

          if (err) {
            this.$message.error(err.message);
            return;
          }
          this.getHouseInfo();
          if (code === 0) {
            this.$message({
              type: "success",
              duration: 1500,
              message: "关注成功",
            });
            this.isEstateAttention = true;
          }
        }
      } else {
        this.$store.commit("updateState", {
          data: true,
          key: "showLogin",
        });
      }
    },
    // 获取关注状态
    async loadHouseCollection(guid) {
      const [err, { data }] = await tool.to(
        userServer.getCollectionStatus(guid, 2)
      );
      if (err) {
        console.log("getCollectionStatus :>>", err);
        return;
      }
      this.isEstateAttention = data;
      // console.log("getCollectionStatus :>>", data);
    },

    coverImgErr(event) {
      var img = event.srcElement;
      img.src = this.defaultCoverImg;
      img.onerror = null;
    },
    toMoreRecommend() {
      if (this.tradeType == 1) {
        this.$router.push("/house/1");
      } else {
        this.$router.push("/house/2");
      }
    },
    setMustSee() {
      const tag = this.houseInfo.tag || []
      const mustSee = tag.includes("必看好房")
      // || (this.houseInfo.houseLevel || 0) == 1 ? true : false
      this.mustSee = mustSee
    }
  },
  created() {
    // this.tradeType=this.$route.params.tradeType
    this.getTradeType();
  },
  mounted() {
    let houseTabs = this.deepCopy(this.houseTabs);
    houseTabs.forEach((item) => {
      let scrollTop = document.querySelector(item.id).offsetTop;
      item.maxTop = scrollTop;
    });
    this.houseTabs = houseTabs;
    window.onscroll = tool.throttle(this.onScroll, 200);
  },
  updated() {
    this.houseTabs.forEach((item) => {
      let scrollTop = document.querySelector(item.id).offsetTop;
      item.maxTop = scrollTop;
    });
  },
  destroyed() {
    window.onscroll = null;
  },
};
</script>

<style lang="less" scoped>
.container {
}
.house-header {
  background: #f5f5f6;
  height: 100px;
  .house-title {
    max-width: 900px;
    .house-title-h {
      max-width: 900px;
      line-height: 29px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      font-size: 26px;
      color: #101d37;
      font-weight: 700;
    }
    .house-title-p {
      max-width: 900px;
    }
  }
}
.house-imgs {
  padding: 28px 0;
  .house-imgs-right {
    // background: rgba(255, 255, 255, 0.39);
    // border: 1px solid #E4E6F0;
    // opacity: 1;
  }
}
.width90 {
  width: 90px;
}
.width130 {
  width: 130px;
}

.house-imgs {
  padding: 28px 0;
}

.house-imgs-right {
  width: 397px;
  margin-left: 65px;
}
.tag-item,
.departmentName {
  padding: 0 3px;
  height: 24px;
  line-height: 24px;
  color: #889ebc;
  background-color: #f2f5f7;
  border-radius: 2px;
}
.activeTag {
  color: #fff;
  background-color: #d53c3c;
}

.tab-bottom {
  width: 50%;
  left: 50%;
  bottom: 0;
  height: 3px;
  transform: translateX(-50%);
}
.feature {
  padding: 32px 0 29px;
}
.feature-tag {
  padding: 0 9px;
  line-height: 30px;
  height: 30px;
  background-color: #f4f7f9;
  color: #849aae;
}

.near-house-item:not(:nth-of-type(4n)) {
  margin-right: 26px;
}
.near-house-item {
  width: 268px;
  margin-bottom: 53px;
  div {
    position: relative;
    left: 0;
    top: 0;
    .tag-item {
      position: absolute;
      top: 0;
      left: 0;
    }
  }
}

.history-title li {
  height: 50px;
  line-height: 50px;
}
.history-item {
  padding: 18px 0 26px;
}
.needFxied {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 10;
  transition: all 0.3s;
}
.needRightFxied {
  position: absolute;
  width: 397px;
  top: 50px;
  right: 5px;
  padding-top: 10px;
  transition: all 0.3s;
  background: #fff;
}

.yezhu-item {
  width: 375px;
  height: 160px;
  margin-left: 12px;
  font-size: 12px;
  color: #fff;
  padding-top: 45px;
  box-sizing: border-box;
  position: relative;
  left: 0;
  top: 0;
  .yezhu-item-marsk {
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0.4;
    position: absolute;
    z-index: 1;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
  }
  .yezhu-item-msg-tit {
    font-size: 20px;
    margin-bottom: 14px;
    z-index: 4;
  }
  .yezhu-item-msg-sub-tit {
    color: #d0d1d2;
    margin-bottom: 14px;
    z-index: 4;
  }
}
.yezhu-item-gujia {
  background: url("../../assets/imgs/yezhu-gujia.png") no-repeat;
  background-size: 100% 100%;
}
.yezhu-item-maifang {
  background: url("../../assets/imgs/yezhu-maifang.png") no-repeat;
}
.yezhu-item-xiazai {
  background: url("../../assets/imgs/yezhu-xiazai.png") no-repeat;
  background-size: 100% 100%;

  .layer-qrcode {
    display: none;
    position: absolute;
    left: 84px;
    bottom: 36px;
    width: 140px;
    padding: 28px;
    background-color: #fff;
    box-shadow: 0 0 6px 0 #ccc;
    line-height: 1;
    .icon-qrcode {
      width: 124px;
      height: 124px;
      margin: 0 auto;
      img {
        width: 100%;
      }
    }
    .txt {
      margin-top: 22px;
      font-weight: 700;
      color: #101d37;
    }
    .sub-txt {
      margin-top: 8px;
      font-size: 12px;
      color: #9399a5;
    }
  }
  .hoverDiv-cu {
    // cursor: alias;
  }
  .hoverDiv:hover {
    .layer-qrcode {
      display: flex;
      flex-direction: column;
      text-align: center;
    }
  }
}
.house-takeLook {
  margin-top: 20px;
}
.house-takeLook {
  .takeLook-item-agentName {
    font-weight: 700;
  }
  .agent-phone {
    display: inline-block;
    font-size: 14px;
    color: #fe615a;
    margin-right: 10px;
    font-weight: 700;
  }
  .takeLook-item-des {
    color: #101d37;
    font-size: 14px;
    line-height: 25px;
    margin: 13px 0;
  }
  .takeLook-item-record {
    color: #b0b3b4;
    font-size: 12px;
    margin-bottom: 19px;
  }
}
.brand_tag {
  width: auto !important;
  padding: 0 2px;
  height: 17px;
  line-height: 17px !important;
  text-align: center;
  border-radius: 2px;
  color: #9399a5 !important;
  font-weight: 400 !important;
  font-size: 11px;
  display: inline-block;
  margin-left: 3px !important;
}

#house-image {
  .container {
    max-height: 1136px;
    overflow: hidden;
    .list {
      margin: 0 auto;
      margin-top: 15px;
      width: 710px;
      text-align: justify;
      line-height: 0;

      .list-item {
        width: 350px;
        height: 230px;
        margin-bottom: 10px;

        display: inline-block;
        position: relative;
        text-align: center;
        img {
          width: 100%;
          height: 100%;
        }
        span {
          display: inline-block;
          position: absolute;
          bottom: 0;
          left: 0;
          line-height: 32px;
          text-align: center;
          padding: 0 11px;
          border-radius: 2px;
          background-color: rgba(0, 0, 0, 0.4);
          color: #fff;
          font-size: 12px;
        }
      }
      .list-item:first-child {
        width: 710px;
        height: 400px;
      }
    }
  }
}
.bikanhaof {
  background: #f8f8f8;
  color: #9399a5;
  height: 38px;
  line-height: 38px;
  font-size: 16px;
  .bikanhaof-tag {
    font-size: 20px;
    background: #d63e3b;
    font-weight: 400;
    line-height: 38px;
    color: #ffffff;
    padding: 0 5px;
  }
}
.chatButton {
  background: #f0fef8;
  border-radius: 1px;
  border: #00ae66 1px solid;
  color: #00ae66;
  .i {
    color: #00ae66;
  }
}
.chatButton:hover {
  box-sizing: border-box;
  background: #fff;
  color: #00ae66;
  border: #00ae66 1px solid;

  .i {
    color: #00ae66;
  }
}

.needFxied-item {
  color: #9399a5;
  line-height: 50px;
  font-size: 16px;
}
</style>

